@use 'variables';

@mixin applyStatusColor($customBackgroundColor, $customLabelColor, $customBorderColor: null) {
  .mat-mdc-standard-chip {
    background-color: #{$customBackgroundColor};

    .mdc-evolution-chip__text-label {
      color: #{$customLabelColor};
    }

    @if $customBorderColor {
      border: 1px solid #{$customBorderColor};
    }
  }

  &.mat-mdc-standard-chip {
    background-color: #{$customBackgroundColor};

    .mdc-evolution-chip__text-label {
      color: #{$customLabelColor};
    }

    @if $customBorderColor {
      border: 0.5px solid #{$customBorderColor};
    }
  }
}

.status-chip {
  .mat-mdc-standard-chip {
    border-radius: 4px;

    .mdc-evolution-chip__text-label {
      font-weight: 500;
    }
  }

  @each $status, $bgColor,
    $labelColor
      in (
        success var(--statusChipSuccessBackgroundColor) var(--statusChipSuccessLabelColor),
        primary var(--statusChipPrimaryBackgroundColor) var(--statusChipPrimaryLabelColor),
        pending var(--statusChipPendingBackgroundColor) var(--statusChipPendingLabelColor),
        error var(--statusChipErrorBackgroundColor) var(--statusChipErrorLabelColor),
        discovery var(--statusChipDiscoveryBackgroundColor) var(--statusChipDiscoveryLabelColor)
      )
  {
    &.#{$status} {
      @include applyStatusColor($bgColor, $labelColor, $labelColor);
    }
  }
}

.category-chip {
  background-color: var(--mat-table-background-color) !important;
  border: solid 1px var(--rp-outline-variant);

  &.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic {
    padding-right: 0;
  }

  &.mat-mdc-standard-chip {
    --mdc-chip-container-height: #{variables.$fontSize * 1.5} !important;
    --mdc-chip-label-text-size: #{variables.$fontSize * 0.875} !important;
  }

  &.number {
    border: none;

    &.mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
      padding: 0 5px;
    }
  }

  .mat-mdc-chip-avatar {
    height: 15px !important;
    width: 15px !important;
  }
}

.client-type-chip {
  &.mat-mdc-standard-chip {
    border-radius: 4px;
    height: #{variables.$fontSize * 1.5};
  }

  &.universal {
    @include applyStatusColor(
      var(--clientTypeChipUniversalBackgroundColor),
      var(--clientTypeChipUniversalLabelColor),
      var(--clientTypeChipUniversalLabelColor)
    );
  }
}

.category-chip-with-remove-button {
  position: relative;
  padding-right: 20px;
  transition: padding-right 0.2s ease-in-out;

  .mat-mdc-chip-graphic {
    padding-right: 0 !important;
  }

  &:hover {
    padding-right: 32px;

    .category-remove-button {
      opacity: 1;
    }
  }

  .category-remove-button {
    position: absolute;
    right: 2px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .remove-data-button {
    color: var(--mat-form-field-error-text-color);
  }
}
