.stepper-sm {
  .mat-horizontal-stepper-header {
    --mat-stepper-header-height: 42px;
  }

  .mat-horizontal-stepper-header[aria-selected='true'] {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.stepper-container-sm {
  .mat-horizontal-content-container {
    padding: 0;
    flex-grow: 1;
  }

  .mat-horizontal-stepper-content {
    height: 100%;
  }
}
