$space: 48px;

.auth-layout {
  background-image: url(../assets/auth_bg.jpg),
    linear-gradient(
      190deg,
      rgb(1, 0, 0) 0%,
      rgb(19, 9, 13) 23%,
      rgb(209, 100, 147) 36%,
      rgb(49, 96, 188) 65%,
      rgb(4, 7, 17) 75%,
      rgb(1, 0, 5) 100%
    );
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.auth-brand {
  .auth-logo {
    padding: 32px;
  }

  .auth-logo-img {
    height: 80px;
    color: #0071eb;
    font-size: 32px;
  }

  .auth-brand-text-container {
    margin-top: -130px;
  }

  .auth-brand-text-1,
  .auth-brand-text-2 {
    text-align: center;
    color: #ffffff;
    font-size: 3rem;
    line-height: 56.25px;
    margin: 0px;
  }

  .auth-brand-text-2 {
    font-size: 1.5rem;
    line-height: 28.13px;
    font-weight: normal;
  }
}

.auth-form {
  background-color: var(--rp-surface-container-lowest);
  border-radius: $space 0px 0px $space;
}

.auth-form-container {
  width: 360px;
  margin-top: $space;
  margin-bottom: $space;

  h2 {
    color: var(--rp-on-surface);
  }

  h3 {
    margin-top: 0px;
  }
}

.login-form {
  width: 360px;
  margin: $space auto;
}

.login-form-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

@media (max-width: 767px) {
  .auth-form {
    border-radius: calc($space/2);
    max-width: 90vw;
    margin: $space auto 0px;
  }

  .auth-brand {
    .auth-logo-img {
      max-width: 80vw;
      height: auto;
    }

    .auth-brand-text-container {
      margin: 0px;
      display: none;
    }
  }
}
