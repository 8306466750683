@use 'sass:map';

$fontSize: 16px;
$fontFamily: Roboto, 'Helvetica Neue', sans-serif;

$primaryColor: #0071eb;
$primaryContainerColor: #004ea5;
$inversePrimaryColor: #adc7ff;
$secondaryColor: #445e8f;
$secondaryContainerColor: #b8ceff;
$onSecondaryContainerColor: #1f3a69;
$warnColor: #ba1a1a;
$text: #181c23;
$surface: #f9f9ff;
$surfaceDim: #d8d9e4;
$surfaceContainerLow: #f2f3fe;
$surfaceContainerLowest: #ffffff;
$surfaceContainerHigh: #e6e8f2;
$onSurface: #181c23;
$inverseSurface: #2d3038;
$inverseOnSurface: #eff0fb;
$outlineVariant: #c1c6d6;

$toolbarTextColor: #000000;

$primaryColorDark: #adc7ff;
$primaryContainerColorDark: #0068d9;
$inversePrimaryColorDark: #005bbf;
$secondaryColorDark: #adc7fe;
$secondaryContainerColorDark: #213d6c;
$onSecondaryContainerColorDark: #bdd1ff;
$warnColorDark: #ffb4ab;
$textDark: #e0e2ec;
$surfaceDark: #10131a;
$surfaceDimDark: #10131a;
$surfaceContainerLowDark: #181c23;
$surfaceContainerLowestDark: #303235;
$surfaceContainerHighDark: #272a32;
$onSurfaceDark: #e0e2ec;
$inverseSurfaceDark: #e0e2ec;
$inverseOnSurfaceDark: #2d3038;
$outlineVariantDark: #414754;
$dividerColor: #d8d9e4;

$toolbarTextColorDark: #ffffff;

$textHeaderColor: #344563;
$textSubHeaedersColor: #42526e;

$avaratBgColor: #e4e8ed;

$menuTextColor: #00316d;
$menuItemActiveBgColor: rgba(#004ea5, 0.12);
$menuItemWidth: 240px;
$menuWidth: $menuItemWidth + 50px;

$schedulerWeeklySelectedBackgroundColor: #d8e2ff;

// mimic status bar
$userMimicStatusBarBgColorLight: #ffcc19;
$userMimicStatusBarTextColorLight: #001a41;
$userMimicStatusBarBgColorDark: #ffe8a5;
$userMimicStatusBarTextColorDark: #001a41;

$infoMessageSuccessBgColor: rgb(215, 226, 255);
$infoMessageErrorBgColor: rgb(255, 215, 215);
$infoMessageWarningBgColor: rgb(255, 246, 215);

// ==================================================
$dark-primary-text: $text;
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: $textDark;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$grey-palette: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  ),
);

// vpn type indicator
$light-vpn-type-indicator-color-active: #1f880e;
$light-vpn-type-indicator-color-inactive: #6f6f6f;
$dark-vpn-type-indicator-color-active: #1f880e;
$dark-vpn-type-indicator-color-inactive: #6f6f6f;

// status colors
$light-status-chip-success-background-color: #e2ffd8;
$light-status-chip-error-background-color: #ffd8d8;
$light-status-chip-primary-background-color: #d8e2ff;
$light-status-chip-pending-background-color: #fffdd8;
$light-status-chip-discovery-background-color: #e4d8ff;
$light-status-chip-success-label-color: #094100;
$light-status-chip-error-label-color: #410000;
$light-status-chip-primary-label-color: #001a41;
$light-status-chip-pending-label-color: #413300;
$light-status-chip-discovery-label-color: #380041;
//to be specified
$dark-status-chip-success-background-color: #094100;
$dark-status-chip-error-background-color: #410000;
$dark-status-chip-primary-background-color: #001a41;
$dark-status-chip-pending-background-color: #413300;
$dark-status-chip-discovery-background-color: #380041;
$dark-status-chip-success-label-color: #e2ffd8;
$dark-status-chip-error-label-color: #ffd8d8;
$dark-status-chip-primary-label-color: #d8e2ff;
$dark-status-chip-discovery-label-color: #e4d8ff;
$dark-status-chip-pending-label-color: #fffdd8;

//customer type
//to be specified
$light-customer-type-chip-universal-background-color: #efeaf0;
$light-customer-type-chip-universal-label-color: #721494;
$dark-customer-type-chip-universal-background-color: #721494;
$dark-customer-type-chip-universal-label-color: #efeaf0;

// table row highlight
$light-default-table-row-hover: rgba(237, 237, 238, 1);
$light-default-table-row-active: rgba(227, 227, 228, 1);
$dark-default-table-row-hover: rgba(230, 224, 233, 0.02);
$dark-default-table-row-active: rgba(230, 224, 233, 0.04);

$light-info-table-row-hover: rgba(237, 235, 202, 1);
$light-info-table-row-active: rgba(227, 225, 194, 1);
$dark-info-table-row-hover: rgba(78, 65, 19, 1);
$dark-info-table-row-active: rgba(85, 72, 28, 1);
