.mat-expansion-panel {
  border-radius: 8px !important;
  box-shadow: none !important;

  .mat-expansion-panel-header-title {
    color: inherit;
  }

  .mat-expansion-panel-header-description {
    color: var(--rp-on-surface);
  }

  .mat-expansion-panel-header.details-table-name {
    pointer-events: none;
  }

  .mat-expansion-panel-header-description.details-table-name {
    pointer-events: all;
  }

  .mat-expansion-panel-header.accordion-header {
    height: auto !important;
  }
}

.expansion-details-panel {
  h6 {
    margin: 0 !important;
  }

  .mat-expansion-panel-body {
    padding: 0 24px 24px;
  }
}
