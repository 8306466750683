@use 'variables';

.table-summery-count {
  font-size: 0.875rem;
  padding-bottom: 1rem;
  margin-top: -1rem;
}

.table-content {
  border-color: var(--rp-table-container-border-color);
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;

  // max-height: max(424px, 70vh);
  /* Adjust this height value as needed */
  overflow-y: visible;
  /* Disable vertical scrolling */

  thead tr {
    background-color: var(--rp-table-header-background-color);

    th {
      border-bottom: none;
    }
  }

  .table-sort-left .mat-sort-header-container {
    justify-content: flex-end;
    flex-direction: row-reverse;

    .mat-sort-header-arrow {
      position: absolute;
      left: -20px;
    }
  }

  a.table-link {
    color: var(--mat-table-row-item-label-text-color);
    text-decoration: auto;

    &:hover {
      color: #0283ff;
      text-decoration: underline;
    }
  }

  td.mat-column-status,
  .table-actions-column {
    width: 80px;
  }

  .table-actions-column-2 {
    width: 130px;
  }

  .table-column-status {
    min-width: calc(var(--mat-table-header-headline-size) * 10);
    width: 16%;
  }

  .table-column-date {
    min-width: calc(var(--mat-table-header-headline-size) * 15);
    width: 20%;
  }

  .table-column-email {
    min-width: calc(var(--mat-table-header-headline-size) * 15);
    width: 20%;
  }

  .table-column-text {
    min-width: calc(var(--mat-table-header-headline-size) * 20);
    width: 30%;
  }

  .mat-mdc-table-sticky-border-elem-right {
    border-left-width: var(--mat-table-row-item-outline-width, 1px);
    border-left-style: solid;
    border-left-color: var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));

    &.mat-mdc-header-cell {
      border-left-color: var(--mat-dialog-vertical-divider, rgba(0, 0, 0, 0.12));
    }
  }

  .table-elem-border-right,
  .header-border-right {
    border-right-width: var(--mat-table-row-item-outline-width, 1px);
    border-right-style: solid;
  }

  .table-elem-border-right {
    border-right-color: var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
  }

  .header-border-right {
    border-right-color: var(--mat-dialog-vertical-divider, rgba(0, 0, 0, 0.12));
  }

  .empty-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    gap: 1rem;
  }

  .empty-table-message-info {
    font-weight: bold;
  }
}

.mat-mdc-chip-set.chip-size-m,
.mat-mdc-chip-set.chip-size-sm {
  .mat-mdc-standard-chip {
    --mdc-chip-label-text-size: #{variables.$fontSize * 0.875};
    --mdc-chip-label-text-line-height: #{variables.$fontSize * 0.875};
    --mdc-chip-container-height: #{variables.$fontSize * 1.25};

    .mdc-evolution-chip__action--primary {
      padding-left: #{variables.$fontSize * 0.5};
      padding-right: #{variables.$fontSize * 0.5};
    }
  }
}

.chip-size-sm .mat-mdc-chip-action-label {
  --mdc-chip-label-text-size: 12px;
}

.mat-elevation-z8 {
  border: 1px solid #e4e8ed;
  box-shadow: none;
}

.table-buttons.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  padding: calc(calc(var(--mdc-icon-button-state-layer-size, 40px) - var(--mdc-icon-button-icon-size, 20px)) / 2);
}

.mat-mdc-row {
  &:hover {
    background-color: var(--mat-default-table-row-hover-color);

    .category-chip {
      background-color: var(--mat-default-table-row-hover-color) !important;
    }
  }
}

.row-expandable {
  cursor: pointer;

  &:active {
    background-color: var(--mat-default-table-row-active-color);

    .category-chip {
      background-color: var(--mat-default-table-row-active-color) !important;
    }
  }
}

.info-background,
.info-background-expandable {
  background-color: var(--mat-table-warning-background-color) !important;

  &:hover {
    background-color: var(--mat-info-table-row-hover-color) !important;

    .category-chip {
      background-color: var(--mat-info-table-row-hover-color) !important;
    }
  }

  .category-chip {
    background-color: var(--mat-table-warning-background-color) !important;
  }
}

.info-background-expandable {
  &:active {
    background-color: var(--mat-info-table-row-active-color) !important;

    .category-chip {
      background-color: var(--mat-info-table-row-active-color) !important;
    }
  }
}
