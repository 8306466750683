.snack-bar-action-message {
  &.info-success-panel .mat-mdc-snackbar-surface {
    background-color: var(--rp-info-message-success-bg-color);
    color: var(--rp-info-message-success-text-color);
  }

  &.info-error-panel .mat-mdc-snackbar-surface {
    background-color: var(--rp-info-message-error-bg-color);
    color: var(--rp-info-message-error-text-color);
  }

  &.info-warning-panel .mat-mdc-snackbar-surface {
    background-color: var(--rp-info-message-warning-bg-color);
    color: var(--rp-info-message-warning-text-color);
  }
}
