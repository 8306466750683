.modal-dialog {
  position: relative;
  border-bottom: 1px solid var(--mat-stepper-line-color);

  button.dialog-close-button,
  button[mat-dialog-close] {
    position: absolute;
    right: 1rem;
    top: 0.8rem;
  }

  button.expand-button {
    position: absolute;
    right: 4rem;
    top: 0.8rem;
  }
}

.mat-mdc-dialog-container .mat-mdc-dialog-title + .mat-mdc-dialog-content {
  color: var(--rp-text-color);
}

.dialog-fields-list {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.dialog-list {
  .mdc-list-item.mdc-list-item--with-one-line {
    height: 25px !important;
  }
}

.hide-overflow-x {
  .mat-mdc-dialog-surface {
    overflow-x: hidden;
  }
}
