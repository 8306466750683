.disabled-checkbox {
  .mdc-checkbox__native-control:disabled:checked ~ .mdc-checkbox__background {
    background-color: var(--rp-primary-color) !important;
  }
}

.checkbox-error {
  .error-text-message {
    position: static !important;
  }
}
