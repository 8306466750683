.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &::after {
    content: '';
    display: block;
  }
}

.text-align-center {
  text-align: center;
}

.text-overflow-hidden {
  overflow: hidden !important;
}

.text-break-word {
  word-break: break-all;
}
