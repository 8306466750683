@use 'sass:color';
@use '@angular/material' as mat;
@use 'styles/variables' as variables;
@use 'styles/theme/typography' as typography;
@use 'styles/theme/background' as background;
@use 'styles/theme/foreground' as foreground;
@use 'styles/theme/light' as light;
@use 'styles/theme/dark' as dark;
@include mat.elevation-classes();
@include mat.app-background();

$my-primary-light: mat.m2-define-palette(light.$restore-point-ai-palette-light, 500);
$my-accent-light: mat.m2-define-palette(light.$restore-point-ai-palette-accept-light, 200, 100, 300);
$my-warn-light: mat.m2-define-palette(light.$restore-point-ai-palette-warn-light);

$restore-point-ai-theme-light: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-primary-light,
      accent: $my-accent-light,
      warn: $my-warn-light,
    ),
    typography: typography.$my-typography,
    density: 0,
    foreground: foreground.$light-theme-foreground-palette,
    background: background.$light-theme-background-palette,
  )
);

$my-primary-dark: mat.m2-define-palette(dark.$restore-point-ai-palette-dark, 500);
$my-accent-dark: mat.m2-define-palette(dark.$restore-point-ai-palette-accept-dark, 200, 100, 300);
$my-warn-dark: mat.m2-define-palette(dark.$restore-point-ai-palette-warn-dark);

$restore-point-ai-theme-dark: mat.m2-define-dark-theme(
  (
    color: (
      primary: $my-primary-dark,
      accent: $my-accent-dark,
      warn: $my-warn-dark,
    ),
    density: 1,
    foreground: foreground.$dark-theme-foreground-palette,
    background: background.$dark-theme-background-palette,
  )
);

// ====================================

@include mat.all-component-themes($restore-point-ai-theme-light);
@include mat.all-component-typographies(typography.$my-typography);

.vpn-status-chip {
  --vpnStatusLabelColorSuccess: #{variables.$light-status-chip-success-background-color};
  --vpnStatusLabelColorSuccessBorder: #{variables.$light-status-chip-success-label-color};
  --vpnStatusLabelColorProgress: #{variables.$light-status-chip-pending-background-color};
  --vpnStatusLabelColorProgressBorder: #{variables.$light-status-chip-pending-label-color};
  --vpnStatusLabelColorSubmitted: #{variables.$light-status-chip-discovery-background-color};
  --vpnStatusLabelColorSubmittedBorder: #{variables.$light-status-chip-discovery-label-color};
}

.status-chip {
  --statusChipSuccessBackgroundColor: #{variables.$light-status-chip-success-background-color};
  --statusChipErrorBackgroundColor: #{variables.$light-status-chip-error-background-color};
  --statusChipPrimaryBackgroundColor: #{variables.$light-status-chip-primary-background-color};
  --statusChipPendingBackgroundColor: #{variables.$light-status-chip-pending-background-color};
  --statusChipDiscoveryBackgroundColor: #{variables.$light-status-chip-discovery-background-color};
  --statusChipSuccessLabelColor: #{variables.$light-status-chip-success-label-color};
  --statusChipErrorLabelColor: #{variables.$light-status-chip-error-label-color};
  --statusChipPrimaryLabelColor: #{variables.$light-status-chip-primary-label-color};
  --statusChipPendingLabelColor: #{variables.$light-status-chip-pending-label-color};
  --statusChipDiscoveryLabelColor: #{variables.$light-status-chip-discovery-label-color};
}

.client-type-chip {
  --clientTypeChipUniversalBackgroundColor: #{variables.$light-customer-type-chip-universal-background-color};
  --clientTypeChipUniversalLabelColor: #{variables.$light-customer-type-chip-universal-label-color};
}

.snack-bar-action-message {
  --mdc-snackbar-container-color: #{variables.$infoMessageSuccessBgColor};
  --mdc-snackbar-supporting-text-color: #{variables.$dark-primary-text};
}

html {
  & {
    color-scheme: light;
  }

  & {
    --display-light: block;
    --display-dark: none;

    --rp-primary-color: #{variables.$primaryColor};
    --rp-primary-container-color: #{variables.$primaryContainerColor};
    --rp-secondary-color: #{variables.$secondaryColor};
    --rp-secondary-container-color: #{variables.$secondaryContainerColor};
    --rp-on-secondary-container-color: #{variables.$onSecondaryContainerColor};
    --rp-surface: #{variables.$surface};
    --rp-on-surface: #{variables.$onSurface};
    --rp-surface-container-low: #{variables.$surfaceContainerLow};
    --rp-surface-container-lowest: #{variables.$surfaceContainerLowest};
    --rp-outline-variant: #{variables.$outlineVariant};
    --rp-secondary-text-color: #{variables.$secondaryColor};

    --mdc-dialog-subhead-color: #{variables.$dark-primary-text};
    --mdc-dialog-supporting-text-color: #{variables.$dark-primary-text};
    --mat-dialog-vertical-divider: #{variables.$dividerColor};

    --mdc-radio-selected-icon-color: #{variables.$primaryColor};
    --mat-standard-button-toggle-selected-state-background-color: #{variables.$schedulerWeeklySelectedBackgroundColor};

    --mat-sidenav-container-divider-color: #{mat.m2-get-color-from-palette($my-primary-light, 40)};
    --mat-sidenav-container-text-color: #{mat.m2-get-color-from-palette($my-primary-light, default)};
    --mat-sidenav-container-background-color: var(--rp-surface-container-lowest);
    --mat-sidenav-content-background-color: #f9f9ff;
    --mat-sidenav-content-text-color: #{variables.$dark-primary-text};

    --rp-table-container-border-color: #{variables.$surfaceContainerHigh};
    --rp-table-header-background-color: #{variables.$surfaceContainerHigh};
    --mat-table-row-item-label-text-color: #{variables.$onSurface};
    --mat-table-header-headline-color: #{variables.$onSurface};
    --mat-table-row-item-outline-color: #{variables.$surfaceContainerHigh};
    --mat-table-background-color: #{variables.$surfaceContainerLowest};
    --mat-table-warning-background-color: #{variables.$light-status-chip-pending-background-color};
    --mat-info-table-row-hover-color: #{variables.$light-info-table-row-hover};
    --mat-info-table-row-active-color: #{variables.$light-info-table-row-active};
    --mat-default-table-row-hover-color: #{variables.$light-default-table-row-hover};
    --mat-default-table-row-active-color: #{variables.$light-default-table-row-active};
    --mat-expansion-container-background-color: #{variables.$surfaceContainerLowest};
    --mat-expansion-container-text-color: #{variables.$text};

    --mat-menu-item-label-text-color: #{variables.$onSurface};
    --mat-menu-item-icon-color: #{variables.$onSurface};
    --mat-menu-item-hover-state-layer-color: rgba(variables.$onSurface, 0.08);
    --mat-menu-item-focus-state-layer-color: rgba(variables.$onSurface, 0.16);
    --mat-menu-container-color: #{variables.$surfaceContainerLowest};
    --mat-menu-divider-color: rgba(variables.$onSurface, 0.32);
    --mdc-plain-tooltip-supporting-text-color: #{variables.$inverseOnSurface};
    --mdc-plain-tooltip-container-color: #{variables.$inverseSurface};
    --rp-text-color: #{variables.$text};
    --rp-avatar-bg-color: #{variables.$avaratBgColor};
    --rp-menu-text-color: var(--rp-primary-color);
    --rp-menu-active-item-bg-color: #{variables.$menuItemActiveBgColor};

    --rp-info-message-success-bg-color: #{variables.$infoMessageSuccessBgColor};
    --rp-info-message-success-text-color: #{variables.$dark-primary-text};
    --rp-info-message-error-bg-color: #{variables.$infoMessageErrorBgColor};
    --rp-info-message-error-text-color: #{variables.$dark-primary-text};
    --rp-info-message-warning-bg-color: #{variables.$infoMessageWarningBgColor};
    --rp-info-message-warning-text-color: #{variables.$dark-primary-text};

    --rp-user-mimic-status-bar-bg-color: #{variables.$userMimicStatusBarBgColorLight};
    --rp-user-mimic-status-bar-text-color: #{variables.$userMimicStatusBarTextColorLight};
  }

  .mat-toolbar {
    --mat-toolbar-container-background-color: var(--rp-surface-container-lowest);
    --mat-toolbar-container-text-color: #{variables.$toolbarTextColor};
    border-bottom: 1px solid var(--mat-sidenav-container-divider-color);
  }
}

// =======================================

.theme-dark {
  @include mat.all-component-themes($restore-point-ai-theme-dark);

  & {
    color-scheme: dark;
  }

  & {
    --display-light: none;
    --display-dark: block;

    --rp-text-color: #{variables.$textDark};
    --rp-primary-color: #{variables.$primaryColorDark};
    --rp-primary-container-color: #{variables.$primaryContainerColorDark};
    --rp-secondary-color: #{variables.$secondaryColorDark};
    --rp-secondary-container-color: #{variables.$secondaryContainerColorDark};
    --rp-on-secondary-container-color: #{variables.$onSecondaryContainerColorDark};
    --rp-surface: #{variables.$surfaceDark};
    --rp-on-surface: #{variables.$onSurfaceDark};
    --rp-surface-container-low: #{variables.$surfaceContainerLowDark};
    --rp-surface-container-lowest: #{variables.$surfaceContainerLowestDark};
    --rp-outline-variant: #{variables.$outlineVariantDark};
    --rp-secondary-text-color: #{variables.$secondaryColorDark};

    --mdc-dialog-subhead-color: #{variables.$light-primary-text};
    --mdc-dialog-supporting-text-color: #{variables.$light-primary-text};
    --mat-dialog-vertical-divider: #{variables.$surfaceDimDark};

    --mat-standard-button-toggle-selected-state-background-color: #{variables.$schedulerWeeklySelectedBackgroundColor};
    --mat-standard-button-toggle-selected-state-text-color: #{variables.$text};

    --mat-sidenav-container-divider-color: #{mat.m2-get-color-from-palette($my-primary-light, 40)};
    --mat-sidenav-container-text-color: #{mat.m2-get-color-from-palette($my-primary-light, default)};
    --mat-sidenav-container-background-color: var(--rp-surface-container-lowest);
    --mat-sidenav-content-background-color: #1d1e21;
    --mat-sidenav-content-text-color: #{variables.$light-primary-text};

    --rp-table-container-border-color: #{variables.$surfaceContainerHighDark};
    --rp-table-header-background-color: #{variables.$surfaceContainerHighDark};
    --mat-table-row-item-label-text-color: #{variables.$onSurfaceDark};
    --mat-table-header-headline-color: #{variables.$onSurfaceDark};
    --mat-table-row-item-outline-color: #{variables.$surfaceContainerHighDark};
    --mat-table-background-color: #{variables.$surfaceContainerLowestDark};
    --mat-table-warning-background-color: #{variables.$dark-status-chip-pending-background-color};
    --mat-info-table-row-hover-color: #{variables.$dark-info-table-row-hover};
    --mat-info-table-row-active-color: #{variables.$dark-info-table-row-active};
    --mat-default-table-row-hover-color: #{variables.$dark-default-table-row-hover};
    --mat-default-table-row-active-color: #{variables.$dark-default-table-row-active};
    --mat-expansion-container-background-color: #{variables.$surfaceContainerLowestDark};
    --mat-expansion-container-text-color: #{variables.$textDark};

    --mdc-plain-tooltip-supporting-text-color: #{variables.$inverseOnSurfaceDark};
    --mdc-plain-tooltip-container-color: #{variables.$inverseSurfaceDark};

    --mat-menu-item-label-text-color: #{variables.$onSurfaceDark};
    --mat-menu-item-icon-color: #{variables.$onSurface};
    --mat-menu-item-hover-state-layer-color: rgba(variables.$onSurfaceDark, 0.08);
    --mat-menu-item-focus-state-layer-color: rgba(variables.$onSurfaceDark, 0.16);
    --mat-menu-container-color: #{variables.$surfaceContainerLowestDark};
    --mat-menu-divider-color: rgba(variables.$onSurfaceDark, 0.32);

    --rp-menu-text-color: #{variables.$primaryColorDark};
    $menuActive: color.adjust(variables.$primaryColorDark, $alpha: -0.88);
    --rp-menu-active-item-bg-color: #{$menuActive};

    --rp-user-mimic-status-bar-bg-color: #{variables.$userMimicStatusBarBgColorDark};
    --rp-user-mimic-status-bar-text-color: #{variables.$userMimicStatusBarTextColorDark};
  }

  .mat-toolbar {
    --mat-toolbar-container-background-color: var(--rp-surface-container-lowest);
    --mat-toolbar-container-text-color: #{variables.$toolbarTextColorDark};
    border-bottom: 1px solid var(--mat-sidenav-container-divider-color);
  }

  .vpn-status-chip {
    --vpnStatusLabelColorSuccess: #{variables.$dark-status-chip-success-background-color};
    --vpnStatusLabelColorSuccessBorder: #{variables.$dark-status-chip-success-label-color};
    --vpnStatusLabelColorProgress: #{variables.$dark-status-chip-pending-background-color};
    --vpnStatusLabelColorProgressBorder: #{variables.$dark-status-chip-pending-label-color};
    --vpnStatusLabelColorSubmitted: #{variables.$dark-status-chip-discovery-background-color};
    --vpnStatusLabelColorSubmittedBorder: #{variables.$dark-status-chip-discovery-label-color};
  }

  .status-chip {
    --statusChipSuccessBackgroundColor: #{variables.$dark-status-chip-success-background-color};
    --statusChipErrorBackgroundColor: #{variables.$dark-status-chip-error-background-color};
    --statusChipPrimaryBackgroundColor: #{variables.$dark-status-chip-primary-background-color};
    --statusChipPendingBackgroundColor: #{variables.$dark-status-chip-pending-background-color};
    --statusChipDiscoveryBackgroundColor: #{variables.$dark-status-chip-discovery-background-color};
    --statusChipSuccessLabelColor: #{variables.$dark-status-chip-success-label-color};
    --statusChipErrorLabelColor: #{variables.$dark-status-chip-error-label-color};
    --statusChipPrimaryLabelColor: #{variables.$dark-status-chip-primary-label-color};
    --statusChipPendingLabelColor: #{variables.$dark-status-chip-pending-label-color};
    --statusChipDiscoveryLabelColor: #{variables.$dark-status-chip-discovery-label-color};
  }

  .client-type-chip {
    --clientTypeChipUniversalBackgroundColor: #{variables.$dark-customer-type-chip-universal-background-color};
    --clientTypeChipUniversalLabelColor: #{variables.$dark-customer-type-chip-universal-label-color};
  }
}

body {
  --mat-sidenav-container-width: 248px;

  --rp-page-font-size: #{variables.$fontSize};
  --rp-menu-item-width: #{variables.$menuItemWidth};

  --mdc-filled-button-container-shape: 8px;
  --mdc-outlined-button-container-shape: 8px;
  --mdc-outlined-text-field-container-shape: 8px;

  --mat-form-field-container-vertical-padding: 12px;
  --mat-form-field-container-height: 48px;
  --mdc-text-button-container-height: 40px;
  --mdc-filled-button-container-height: 40px;
  --mdc-outlined-button-container-height: 40px;
  --mdc-protected-button-container-height: 40px;

  --mdc-dialog-container-shape: #{variables.$fontSize};
  --mat-dialog-headline-padding: 9px 24px 16px 24px;
  --mdc-dialog-subhead-size: #{variables.$fontSize * 1.75};

  --mat-table-header-container-height: 48px;
  --mat-table-footer-container-height: 48px;
  --mat-table-row-item-container-height: 48px;
  --mat-table-header-headline-size: #{variables.$fontSize};
}
