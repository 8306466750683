@use 'variables' as variables;

.dialog-tab-group {
  --mat-tab-header-active-hover-label-text-color: var(--rp-on-secondary-container-color);
  --mat-tab-header-active-label-text-color: var(--rp-on-secondary-container-color);
  --mat-tab-header-active-focus-label-text-color: var(--rp-on-secondary-container-color);
  --mat-tab-header-inactive-label-text-color: #{variables.$text};
  --mat-tab-header-inactive-hover-label-text-color: #{variables.$text};

  .dialog-tab {
    flex: 1;
    border: 1px solid #cccccc;
    font-weight: 500;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-right: none;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.mdc-tab--active {
      background-color: var(--rp-secondary-container-color);
    }
  }

  .mdc-tab-indicator {
    display: none;
  }

  .dialog-tab-body .mat-mdc-tab-body-content {
    padding-top: variables.$fontSize * 1.5;
  }
}
