.menu-shadow {
  border: none;
  box-shadow:
    0px 0px 1px 0px var(--rp-surface-container-low),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3) !important;
}

.statusInfo {
  border-color: var(--mdc-plain-tooltip-container-color);

  .mat-mdc-menu-content {
    color: var(--mdc-plain-tooltip-supporting-text-color);
    background-color: var(--mdc-plain-tooltip-container-color);
  }
}

.menu-navigation {
  --mdc-list-list-item-focus-state-layer-color: transparent;
}

.menu-item {
  display: flex;
  align-items: center;
  text-decoration: unset;
  color: var(--rp-menu-text-color);
  width: var(--rp-menu-item-width);
  font-weight: 500;
  cursor: pointer;
}

.menu-item-text {
  padding-left: 0.5rem;
  float: 1 1 auto;
}
