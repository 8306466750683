.content-container {
  flex: 1 1 auto;
}

a.navbar-brand {
  height: 50px;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin-right: 18px;

  img.brand-logo {
    height: 36px;
  }
}

.tools-panel {
  flex: 1 1 auto;
}

.user-profile-panel {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  background: none;
  border: none;
  text-align: left;

  .user-picture {
    background-color: var(--rp-avatar-bg-color);
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .user-info {
    padding-left: 1rem;
    line-height: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .user-name,
  .user-organization {
    width: 160px;
  }

  .user-name {
    font-size: 1rem;
    cursor: pointer;
    color: var(--rp-primary-color);
  }

  .user-organization {
    font-size: 0.8rem;
    color: var(--rp-secondary-color);
  }
}
