/* You can add global styles to this file, and also import other style files */
@use 'styles/variables' as variables;

@use 'styles/icons';
@use 'styles/grid';
@use 'styles/table';
@use 'styles/menu';
@use 'styles/form';
@use 'styles/dialog';
@use 'styles/snack-bar';
@use 'styles/tab';
@use 'styles/utilities';
@use 'styles/chip';
@use 'styles/text';
@use 'styles/stepper';
@use 'styles/card';
@use 'styles/auth';
@use 'styles/notification';
@use 'styles/header';
@use 'styles/expansion-panel';
@use 'styles/tooltip';
@use 'styles/checkbox';
@use 'styles/drawer';

html,
body {
  height: 100%;
  font-size: variables.$fontSize;
  font-family: variables.$fontFamily;
}

body {
  margin: 0;
}

.page-title-block {
  margin-top: variables.$fontSize * 1.5;
  margin-bottom: variables.$fontSize * 1.5;
}

h1.page-title {
  font-size: variables.$fontSize * 1.5;
  line-height: variables.$fontSize * 2;
  margin: 0;
}
