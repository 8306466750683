.notifications-list {
  .notification {
    display: flex;
    flex-direction: row;
    gap: 4px;
    padding: 8px 16px 8px 16px;
  }

  .notification-status-container {
    flex: 0 0 auto;
  }

  .notification-status {
    width: 44px;
    height: 44px;
    border-radius: 8px;
    background-color: #cccccc;
  }

  .notification-status-data {
    flex: 1 1 auto;
  }
}
