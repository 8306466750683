.mat-field-fluid {
  width: 100%;
}

.mat-mdc-form-field {
  .mat-mdc-radio-button.mat-mdc-radio-checked {
    --mat-radio-checked-ripple-color: none !important;
  }
}

.mat-mdc-form-field.input-normalize {
  border-radius: 8px;
  background-color: var(--mat-select-panel-background-color);

  .mat-mdc-form-field-infix {
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: 40px;
  }

  .mat-mdc-form-field-icon-prefix > .mat-icon,
  .mat-mdc-form-field-icon-suffix > .mat-icon {
    padding: 8px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.no-error-space {
  .mat-mdc-form-field-bottom-align {
    display: none;
  }
}

button.button-normalize {
  height: 40px;
}

button.button-add {
  min-width: 180px;
}

button.button-sm-size {
  transform: scale(0.7);
}

.ng-untouched {
  .error-text-message {
    display: none;
    visibility: hidden;
  }
}

.mat-mdc-dialog-actions {
  .action-button {
    min-width: 120px;
  }

  .action-button-primary {
    min-width: 170px;
  }

  .action-button-small {
    min-width: 70px;
  }
}

.form-skeleton-item {
  opacity: 0.1;
  background-color: var(--mat-option-selected-state-label-text-color);
  margin-bottom: var(--mat-form-field-subscript-text-line-height);
  border-radius: var(--mdc-shape-small, 4px);
}

.table-form {
  color: var();
  border-collapse: collapse;

  td,
  th {
    &:not(:first-child) {
      padding-left: 16px;
    }
  }

  td {
    text-align: start;
    vertical-align: text-top;
  }

  label.field-label {
    font-weight: 500;
    font-size: var(--rp-text-color);
  }
}

.field-with-hint.mat-mdc-form-field {
  .mat-mdc-form-field-hint-wrapper {
    display: inline-flex;
    position: static;
  }
}

.one-line-hint.mat-mdc-form-field {
  .mat-mdc-form-field-hint-wrapper {
    position: static;
    display: inline-block;
    margin: 5px 0 10px;
  }
}

.connector-select {
  .mat-mdc-form-field {
    min-width: 460px;
  }
}

.textarea-no-resize {
  resize: none !important;
}

.mat-form-no-field-wrapper {
  .mat-mdc-form-field-subscript-wrapper {
    margin-bottom: -1.25em;
  }
}

.vertical-divider-right {
  border-right: 0.5px solid var(--mat-dialog-vertical-divider);
  padding-right: 1.5rem;
}

.form-error-message {
  .error-text-message {
    position: static !important;
    padding: 0 16px;
  }
}

.hide-scrollbar {
  scrollbar-width: none;
}

.select-option-desccription {
  font-size: 0.875em;
}

.schedure-error {
  position: relative;
  top: -34px;
  left: 1rem;
}

.scheduler-input {
  flex: 1 1 50%;
  min-width: 0;

  .mat-mdc-select-arrow {
    color: var(--rp-on-surface) !important;
  }
}
